// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-header {
    
    padding: 10px 0;
    border-bottom: 3px solid #013d7a;
    display: flex;
    flex-direction: row;
}

.child {
    width: 100%;
    text-align: center;
    color: white;

}


.icon {
    padding: 6px;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/contact-header/index.css"],"names":[],"mappings":"AAAA;;IAEI,eAAe;IACf,gCAAgC;IAChC,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,YAAY;;AAEhB;;;AAGA;IACI,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".contact-header {\n    \n    padding: 10px 0;\n    border-bottom: 3px solid #013d7a;\n    display: flex;\n    flex-direction: row;\n}\n\n.child {\n    width: 100%;\n    text-align: center;\n    color: white;\n\n}\n\n\n.icon {\n    padding: 6px;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
