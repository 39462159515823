
import './App.css';
import Home from './pages/home';
import {Toaster} from 'react-hot-toast';

function App() {
  return (
    <>
    <Toaster/>

    <Home/>
    </>
  );
}

export default App;
