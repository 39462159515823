// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    background-color: rgb(19, 1, 31);
}

.fordev-logo {
    width: 80px;
    height: 80px;
  
}

.fordev-logo img {
    width: 100%;
    height: 100%;
    border-radius: 50px;
}

.icon {
    color: white;   
    height: 50px;
    width: 50px;
}

.details {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-left: 20px;
    padding: 20px;
}     `, "",{"version":3,"sources":["webpack://./src/components/footer/index.css"],"names":[],"mappings":";;;AAGA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,gCAAgC;AACpC;;AAEA;IACI,WAAW;IACX,YAAY;;AAEhB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;IACjB,aAAa;AACjB","sourcesContent":["\n\n\n.footer {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 120px;\n    background-color: rgb(19, 1, 31);\n}\n\n.fordev-logo {\n    width: 80px;\n    height: 80px;\n  \n}\n\n.fordev-logo img {\n    width: 100%;\n    height: 100%;\n    border-radius: 50px;\n}\n\n.icon {\n    color: white;   \n    height: 50px;\n    width: 50px;\n}\n\n.details {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: white;\n    margin-left: 20px;\n    padding: 20px;\n}     "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
