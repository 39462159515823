import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL


export const registerAppointment = async(name, email, organization, phone)=>{

    const url = `${BACKEND_URL}/user/regApmnt`;
    const response = await axios.post(url, {
        name: name, 
        email: email,
        phone: phone,
        organization: organization
    });
    return response.data;
}