import React from 'react'
import './index.css'
import fordevLogo from '../../assets/logo/fordev.png'
import { CiYoutube } from "react-icons/ci";
import { FaLinkedin } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
const Footer = () => {
  return (
    <>
      <div className='footer'>
         <div className='fordev-logo'> 

            <img src={fordevLogo} alt='fordev-logo' />
         </div>
         <div className='details'>

           <a href='https://www.youtube.com/@forDEV-io'> <CiYoutube className='icon'/> </a>
           <a href='https://www.linkedin.com/company/fordevio'> <FaLinkedin className='icon'/> </a>
           <a href=''> <FaSquareXTwitter className='icon'/> </a>
         </div>
      </div>
    </>
  )
}

export default Footer