import React, { useState } from 'react'
import './index.css'
import ContactHeader from '../../components/contact-header'
import awslogo from '../../assets/logo/aws-logo.png'
import dockerlogo from '../../assets/logo/docker-logo.png'
import kubernetelogo from '../../assets/logo/kubernetes-logo.png'
import jenkinslogo from '../../assets/logo/jenkins-logo.png'
import prometheuslogo from '../../assets/logo/prometheus-logo.png'
import terrafomlogo from '../../assets/logo/terraform-logo.png'
import githubactionslogo from '../../assets/logo/github-actions-logo.png'
import linuxlogo from '../../assets/logo/linux-logo.png'
import toast from 'react-hot-toast'
import { registerAppointment } from '../../api/user'
import Footer from '../../components/footer'


const Home = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [organization, setOrganization] = useState('');
  const [phone, setPhone] = useState('');

  const submitHandler = async () => {
    if (name === '' || email === '' || organization === '' || phone === '') {
      toast.error('All fields are required');
      return;
    }
    const func = async () => {
      const res = await registerAppointment(name, email, organization, phone);
      setName('');
      setEmail('');
      setOrganization('');
      setPhone('');
    }
    toast.promise(
      func(),
      {
        loading: 'Registering...',
        success: <b>Registered for appointment!</b>,
        error: <b>Error occured</b>,
      }
    );

  }

  return (
    <>
      <ContactHeader />
      <div className='h1'>
        forDEV
      </div>
      <div className='h1-sub'>Forward - Development</div>
      <div className='h2'>
        Cloud, Devops, Security and Networking solutions for your buisness
      </div>
      <div className='expertise'>
        <p className='h2'>Our Expertise</p>
        <div className='expertise-list'>
          <div className='grid-container'>
            <div className='logo' >
              <img src={awslogo} alt='aws-logo' />
            </div>
            <div className='logo' >
              <img src={dockerlogo} alt='docker-logo' />
            </div>
            <div className='logo' >
              <img src={kubernetelogo} alt='docker-logo' />
            </div>
            <div className='logo' >
              <img src={linuxlogo} alt='docker-logo' />
            </div>
            <div className='logo' >
              <img src={jenkinslogo} alt='docker-logo' />
            </div>
            <div className='logo' >
              <img src={prometheuslogo} alt='docker-logo' />
            </div>
            <div className='logo' >
              <img src={terrafomlogo} alt='docker-logo' />
            </div>
            <div className='logo' >
              <img src={githubactionslogo} alt='docker-logo' />
            </div>
          </div>
        </div>

      </div>

      <div>
        <p className='h2'>Our Products</p>
        <div className="product-list">
          <div className='product'>
            <p className='p-h1'> Wharf</p>
            <p className='p-h2'> A tool to organize monolithic server</p>
            <a href='https://github.com/fordevio/wharf' className='p-h3'>Learn More</a>
          </div>
          <div className='product'>
            <p className='p-h1'>Terraform Modules</p>
            <p className='p-h2'> A collection of terraform modules to make creation of infrastructure easy</p>
            <a href='https://github.com/orgs/fordevio/repositories' className='p-h3'>Learn More</a>
          </div>

        </div>
      </div>
      <div className=''>
        <p className='h2'>Blog series</p>
        <div className="product-list">
          <div className='product'>
            <p className='p-h1'> Introduction to Docker</p>
            <p className='p-h2'> Author ~ Yash Patel</p>
            <a href='https://medium.com/@fordev951/introduction-to-docker-7f0ccb18d944' className='p-h3'>Read Here</a>
          </div>


        </div>
      </div>
      <div >
        <p className='h2'>Book an appointment</p>
        <div className='form'>
          <div className='input-box'>
            <span className='form-label'>Name*:    </span>
            <input type='text' onChange={(e) => setName(e.target.value)} className='form-input' value={name} />
          </div>
          <div className='input-box'>
            <span className='form-label'>Organization*:    </span>
            <input type='text' onChange={(e) => setOrganization(e.target.value)} className='form-input' value={organization} />
          </div>
          <div className='input-box'>
            <span className='form-label'>Email*:    </span>
            <input type='email' onChange={(e) => setEmail(e.target.value)} className='form-input' value={email} />
          </div>
          <div className='input-box'>
            <span className='form-label'>Number*:    </span>
            <input type='text' onChange={(e) => setPhone(e.target.value)} className='form-input' value={phone} />
          </div>
          <div className='input-box'>
            <button className='form-button' onClick={submitHandler}>Submit</button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Home