import React from 'react'
import './index.css'
import { FaPhone, FaEnvelope } from 'react-icons/fa';

const ContactHeader = () => {
  return (
    <header className="contact-header">
     <div className='child'>
        <FaPhone className='icons'/>
        <span>+91-8602371342</span>
    </div>   
    <div className='child'>
        <FaEnvelope className='icons'/>
        <span>fordev951@gmail.com</span>
    </div>   
   </header>

  )
}

export default ContactHeader